import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Employee from '../components/employee'

// resource imports
import dave_jpg from '../images/team/davidP.jpg'
import lisa_jpg from '../images/team/lisaP.jpg'
import daved_jpg from '../images/team/davedP.jpg'
import katie_jpg from '../images/team/katieR.jpg'
import ryan_jpg from '../images/team/ryanG.jpg'
import tyler_jpg from '../images/team/tylerP.jpg'

const about = () => (
  <Layout>
    <SEO title="About" />

    {/* <div className="row align-items-center mb-5">
        <div className="col-lg-12">
            <h1 className="font-weight-light color-dark text-center py-3">
                <b>About Carousel Hat</b>
            </h1>
        </div>
    </div> */}

    <div className="row align-items-center my-5 bg-light rounded border-light-grey drop-shadow-small">
        <div className="col-lg-12">
            <div class="container color-dark-grey mb-4">
                <h1 className="font-weight-light color-dark-grey text-center pt-3">
                    <b> What We're All About </b>
                </h1>
                <hr className="mx-5"/>
                <p className="px-4 pt-2">
                    Welcome to Carousel Hat! Dave, Lisa, and the rest of our team have ambitions that go far beyond just sales. 
                    We have always been about helping, teaching, and providing a unique experience by sharing our passion for creating. 
                    We back up all of our work and always try to go the extra mile to ensure the best experience possible.
                    <br /><br />
                    Our philosophy has always been to share our knowledge with anyone interested in learning. 
                    Our doors are open for all that would like to see our shop and the pride we take in it.
                    <br /> <br /> 
                </p>
            </div>
        </div>
    </div>

    <div className="row align-items-center my-5 bg-light rounded border-light-grey drop-shadow-small">
        <div className="col-lg-12">
            <div class="container color-dark-grey mb-4">
                <h1 className="font-weight-light color-dark-grey text-center pt-3">
                    <b> A Brief History </b>
                </h1>
                <hr className="mx-5"/>
                <p className="p-3">
                    <b>1991 - </b> Dave first started learning how to screen print in a graphics class during his senior year of high school. 
                        His first shirts were for himself and his friends in motocross. 
                        He also took over the graphic design portion of his family's sign shop for several years which helped him learn to print and create on his own. 
                        His first press was a 1 color press featuring "Jiffy Hinges", air dry ink, and a healthy dose of homebrew engineering.
                    <br /><br />  
                    <b>1994 - </b> Dave began working in the <strike>Cardboard Box</strike> Corrugated Container industry while still working part-time for his family's sign shop until <b>1998</b>
                    <br /><br />    
                    <b>2008 - </b> Dave transitioned out of manufacturing and into the IT side of the industry. 
                        Here he worked with project teams to implement manufacturing software. 
                        It was this transition that ignited Dave's passion for teaching, as he was able to combine his technical knowledge and numerous years of manufacturing experience to teach diverse groups all over the world.   
                    <br /><br />  
                    <b>2013 - </b> Carousel Hat Clothing, LLC begins! Instead of homebrew engineering, the first press for the company was a used 6 color, 4 station press. 
                        Amazingly, it was only $100 and was delivered, free of charge, to Carousel Hat HQ (which may or may not have been a garage).
                    <br /><br />  
                    <b>2014 - </b> The business has continued to grow, and moves into a nearby small shop. 
                    <br /><br />  
                    <b>2016 - </b> Lisa, who had worked with Dave for nearly a decade in the corrugated industry, joined the team taking over many of the consulting and business management tasks. 
                        Lisa has a business degree in Operations Management from the University of Massachusetts and made an immediate impact on the Company.
                    <br /><br />  
                    <b>2017 - </b> After expanding to wide format printing, the small shop is getting too cramped. 
                        Dave and Lisa decide to make the jump and invest in a bigger space. 
                        <b> Daved </b> also joins the team part-time while pursuing his degree in accounting.
                    <br /><br />  
                    <b>2018 - </b> Carousel Hat got its first automatic press! Our ROQ You Xl press arrived and made a huge impact on our daily operations. And it looks fantastic!
                    <br /><br />  
                    <b>2020 - </b>
                    <br /><br />
                    <b>2021 - </b> Proud to have made it through 2020
                </p>
            </div>
        </div>
    </div>
    
    <div className="row align-items-center my-5 bg-light rounded border-light-grey drop-shadow-small">
        <div className="col-lg-12">
            <div class="container color-dark-grey mb-4">
                <h1 className="font-weight-light color-dark-grey text-center pt-3">
                    <b> Our Team </b>
                </h1>
                <div className="container">
                <hr className="mx-5"/>
                    <div className="row justify-content-center">
                        <Employee img={dave_jpg} name="Dave" title="Owner" tagline="Still hasn't seen deadpool"  />
                        <Employee img={lisa_jpg} name="Lisa" title="Project Manager" tagline="Instigates... innocently"  />
                        <Employee img={daved_jpg} name="Daved" title="Accounting" tagline="'Yeah boy' - Daved (2019)"  />
                        <Employee img={katie_jpg} name="Katie" title="Operations" tagline="No clutter Allowed"  />
                        <Employee img={tyler_jpg} name="Tyler" title="Operations/Design" tagline="Tyler.exe is having trouble responding"  />
                        <Employee img={ryan_jpg} name="Ryan" title="Web Dev" tagline="Jack of all trades, master of pun"  />
                    </div>
                </div>
            </div>
        </div>
    </div>

  </Layout>
)

export default about