import React from "react"

import * as EmployeeStyle from '../styles/modules/employee.module.scss'

const employee = ({img, name, title, tagline}) => (
    <div className=" col-sm-6 col-md-4 text-center p1">
        <div className={EmployeeStyle.teamDiv} >
            <img src={img} class={EmployeeStyle.teamImg} alt={name + ',' + title} /><br />
            <span className={EmployeeStyle.teamName}> {name} </span><br />
            <span className={EmployeeStyle.teamTitle}> {title} </span><br />
            <span className={EmployeeStyle.teamSubTitle}> {tagline} </span>
        </div>
    </div>
)

export default employee